import React from "react";
import {graphql, useStaticQuery} from "gatsby";
import Layout from "../common/Layout";
import styles from "../../css/damera.module.css";
import {SectionCon} from "./Project";
import Helmet from "react-helmet";
import logo from "../../images/on-going-projects/damera/logo.png";
import BannerText from "../../images/on-going-projects/damera/banner-text.png";
import MainImg from "../../images/on-going-projects/damera/main.png";
import SectionAsideImg from "../../images/on-going-projects/damera/section-aside.png";
import SynopsisImg from "../../images/on-going-projects/damera/synopsis.png";
import BgPattern1 from "../../images/on-going-projects/damera/bg-pattern/1.png";
import BgPattern2 from "../../images/on-going-projects/damera/bg-pattern/2.png";
import BgPattern3 from "../../images/on-going-projects/damera/bg-pattern/3.png";
import ProjectDetails from "./ProjectDetails";
import Highlights from "./damera/Highlights";
import AccordionItem from "./AccordionItem";
import ProjectGallery from "./ProjectGallery";
import {VideoBtn} from "./BannerSection";

const useDameraData = () =>
  useStaticQuery(graphql`
    query {
      green: file(
        relativePath: {
          eq: "on-going-projects/crown-residence/green-open-spaces/1.jpg"
        }
      ) {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid
          }
        }
      }
      ongoingJson(slug: { eq: "royal-damera-gardens" }) {
        banner {
          childImageSharp {
            fluid {
              src
            }
          }
        }
        logo {
          childImageSharp {
            fluid {
              src
            }
          }
        }
        slug
        rera
        title
        tagline
        brouchureUrl
        video {
          type
          link
        }
        sections {
          title
          synopsis
          specification {
            a
            q
          }
          floor_plan {
            title
            pricing
            rera
            images {
              childImageSharp {
                fluid(maxWidth: 1400) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
          }
          highlights
          features {
            title
            image {
              childImageSharp {
                fluid(maxWidth: 1920) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
            largeFont
          }
          gMapUrl
          gallery {
            title
            images {
              childImageSharp {
                fluid(maxWidth: 1920) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
          }
          project_updates {
            title
            images {
              childImageSharp {
                fluid(maxWidth: 1920) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
          }
          walkthrough {
            title
            urls
          }
        }
      }
    }
  `);

const Damera = () => {
  const {ongoingJson} = useDameraData();
  const project = ongoingJson;
  const sections = project.sections || [];
  const current = sections[0] || {};

  return (
    <Layout>
      <Helmet>
        <title>{project.title}</title>
        <meta name="description" content={project.tagline}/>
        <meta property="og:type" content="website"/>
        {project.banner && project.banner.childImageSharp && (
          <meta
            property="og:image"
            content={project.banner.childImageSharp.fluid.src}
          />
        )}
      </Helmet>

      <div className={styles.dameraBannerSection}>
        <div className="container">
          <div className="row">
            <div
              className="col-md-6 my-3 my-md-0"
              style={{position: "relative", width: "100%", zIndex: 10}}
            >
              <div className="row">
                <div className="col-md-6">
                  <div className="d-flex justify-content-center justify-content-md-start">
                    <img className={styles.dameraLogo} src={logo}/>
                  </div>
                </div>
              </div>
            </div>
            <div
              className="col-md-6 mb-md-0"
              style={{position: "relative", width: "100%", zIndex: 10}}
            >
              <div className="row">
                <div className="col-md-6"/>
                <div className="col-md-6">
                  <div className="d-flex flex-column justify-content-center justify-content-md-end">
                    <div className="d-flex justify-content-end align-items-start pr-3 py-3 py-md-0">
                      <VideoBtn video={project.video}/>
                    </div>
                    <div className="text-center">
                      <img
                        className={styles.dameraBannerText}
                        src={BannerText}
                      />
                      <div style={{fontSize: "10px"}}>{project.rera}</div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <ProjectDetails className={styles.dameraTabs} project_updates={current.project_updates}/>
      <img className={styles.dameraMain} src={MainImg}/>
      <div className="pb-4" style={{position: "relative", width: "100%"}}>
        <div className={styles.dameraSynopsisBg}>
          <img src={BgPattern1}/>
        </div>
        <SectionCon>
          <a
            href={`#synopsis`}
            id={"synopsis"}
            aria-hidden="true"
            className="resident-scroll-link"
          />
          <div className="d-flex flex-row align-items-center my-3">
            <img
              className={styles.dameraSectionAsideImg}
              src={SectionAsideImg}
            />
            <h1 className={styles.dameraSectionTitle}>Project Synopsis</h1>
          </div>
          <div className="row">
            <div className="col-md-8">
              <div className={styles.dameraSynopsisText}>
                Even today, it's easy to picture Royal European Courtiers and
                Aristocrats taking a dreamy, soothing stroll through the
                imposingly charming park and gardens of Royal Damera Gardens.
                <br/>
                <br/>
                The master design is poetically dotted with verdant green,
                smartly manicured trees, vibrantly fragrant flower beds,
                well-appointed fountains, ornamental gazebos and multiple
                amenities reminiscent of the French Countryside.
                <br/>
                <br/>
                An opulent stately building looms grandly over two expansive
                gardens. Diligent attempt to whimsically revive the incomparable
                charms of classical facades and infuse them in a contemporary
                context is glaringly evident. The functional aspects of
                structural planning blends seamlessly with the classical
                philosophies of order.
              </div>
            </div>
            <div className="col-md-4">
              <img className={styles.dameraSynopsisImg} src={SynopsisImg}/>
            </div>
          </div>
        </SectionCon>
      </div>
      <Highlights features={current.features}/>
      <SectionCon>
        <a
          href={`#location`}
          id={"location"}
          aria-hidden="true"
          className="resident-scroll-link"
        ></a>
        <div className="d-flex flex-row align-items-center my-3">
          <img className={styles.dameraSectionAsideImg} src={SectionAsideImg}/>
          <h1 className={styles.dameraSectionTitle}>Location</h1>
        </div>
        <div className="row my-5">
          <div className="col-md-4">
            <img className={styles.dameraLocationImg} src={logo}/>
            <div className={styles.dameraLocationText}>
              {project.title}
              <br/>
              No.31, Poes Garden,
              <br/>
              Chennai - 600 018
            </div>
          </div>
          <div className="col-md-8">
            <iframe
              title="google-maps"
              src={current.gMapUrl}
              frameBorder={0}
              height={200}
              style={{border: 0, width: "100%"}}
              allowFullScreen
            />
          </div>
        </div>
      </SectionCon>
      <div className="pb-4" style={{position: "relative", width: "100%"}}>
        <div className={styles.dameraSpecificationBg}>
          <img src={BgPattern2}/>
        </div>
        <SectionCon>
          <a
            href={`#project-specifications`}
            id={"project-specifications"}
            aria-hidden="true"
            className="resident-scroll-link"
          />
          <div className="d-flex flex-row align-items-center my-3">
            <img
              className={styles.dameraSectionAsideImg}
              src={SectionAsideImg}
            />
            <h1 className={styles.dameraSectionTitle}>
              Project Specifications
            </h1>
          </div>
          <div className={styles.dameraAccordion}>
            <ul className="accordion">
              {current.specification.map((item, idx) => (
                <AccordionItem key={idx} {...item} strokeColor={"#442c34"}/>
              ))}
            </ul>
          </div>
        </SectionCon>
      </div>
      <div className="pb-4" style={{position: "relative", width: "100%"}}>
        <div className={styles.dameraGalleryBg}>
          <img src={BgPattern3}/>
        </div>
        <SectionCon>
          <a
            href={`#gallery`}
            id={"gallery"}
            aria-hidden="true"
            className="resident-scroll-link"
          />
          <div className="d-flex flex-row align-items-center my-3">
            <img
              className={styles.dameraSectionAsideImg}
              src={SectionAsideImg}
            />
            <h1 className={styles.dameraSectionTitle}>Gallery</h1>
          </div>
          <div className={styles.dameraGallery}>
            <ProjectGallery gallery={current.gallery}/>
          </div>
        </SectionCon>
      </div>
      <SectionCon>
        <a
          href={`#project-status`}
          id={"project-status"}
          aria-hidden="true"
          className="resident-scroll-link"
        />
        <div className="d-flex flex-row align-items-center my-3">
          <img
            className={styles.dameraSectionAsideImg}
            src={SectionAsideImg}
          />
          <h1 className={styles.dameraSectionTitle}>Project Updates</h1>
        </div>
        <div className={styles.dameraGallery}>
          <ProjectGallery gallery={current.project_updates}/>
        </div>
      </SectionCon>
    </Layout>
  );
};

export default Damera;
