import React, {useCallback, useEffect, useState} from "react";
import {motion, useAnimation,} from "framer-motion";
import {useInView} from "react-intersection-observer";
import Carousel from "nuka-carousel";
import styles from "../../../css/damera.module.css";
import bgImage from "../../../images/on-going-projects/damera/highlight/bg.jpg";

const textVariants = {
    open: {
        y: 0,
        opacity: 1,
        transition: {
            y: {stiffness: 500, velocity: -100}
        }
    },
    closed: {
        y: 50,
        opacity: 0,
        transition: {
            y: {stiffness: 500}
        }
    }
};

const HighlightItem = ({index, title, largeFont, onInView}) => {
    const textControls = useAnimation();
    const [refText, inViewText] = useInView({threshold: 1});
    const [refMobileText, inViewMobileText] = useInView({threshold: 1});

    useEffect(() => {
        textControls.start(inViewText ? "open" : "closed");
        if (inViewText) onInView(index)
    }, [textControls, inViewText, onInView, index]);

    useEffect(() => {
        if (inViewMobileText) onInView(index)
    }, [textControls, inViewMobileText, onInView, index]);

    return (
        <>
            <div className="d-none d-md-block">
                <div className={styles.dameraHighlightTextContainer}>
                    <div className="row align-self-center align-items-center h-100">
                        <div className="col-12">
                            <motion.div
                                ref={refText}
                                animate={textControls}
                                initial="closed"
                                variants={textVariants}
                                className={
                                    largeFont
                                        ? styles.dameraHighlightTextLarge
                                        : styles.dameraHighlightText
                                }
                            >
                                {title}
                            </motion.div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="d-md-none">
                <div className={styles.dameraHighlightTextContainer}>
                    <div className="row align-self-center align-items-center h-100">
                        <div className="col-12">
                            <div
                                ref={refMobileText}
                                className={
                                    largeFont
                                        ? styles.dameraHighlightTextLarge
                                        : styles.dameraHighlightText
                                }
                            >
                                {title}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

const Highlights = ({features = []}) => {
    const [active, setActive] = useState(0);

    const onInView = useCallback(idx => {
        setActive(idx);
    }, []);

    return (
        <section className="relative">
            <div className="abs-full">
                <img src={bgImage} alt="" className={styles.dameraHighlightStickyBg}/>
            </div>
            <div className="d-md-none">
                <div className="container">
                    <div className={styles.dameraHighlightImgContainer}>
                        <div className={styles.dameraHighlightImgCarouselContainer}>
                            <Carousel
                                slideIndex={active}
                                dragging={false}
                                withoutControls
                                swiping={false}
                                speed={1500}
                                animation="fade"
                                scrollMode="remainder"
                                cellAlign="center"
                            >
                                {features.map((feature) => (
                                    <img
                                        key={feature.image.childImageSharp.fluid.src}
                                        className={styles.dameraHighlightImg}
                                        alt=""
                                        src={feature.image.childImageSharp.fluid.src}
                                    />
                                ))}
                            </Carousel>
                        </div>
                    </div>
                    {features.map((feature, index) => (
                        <HighlightItem
                            key={index}
                            index={index}
                            title={feature.title}
                            largeFont={feature.largeFont}
                            onInView={onInView}
                        />
                    ))}
                </div>
            </div>
            <div className="d-none d-md-block">
                <div className="container">
                    <div className="row">
                        <div className="col-md-6">
                            {features.map((feature, index) => (
                                <HighlightItem
                                    key={index}
                                    index={index}
                                    title={feature.title}
                                    largeFont={feature.largeFont}
                                    onInView={onInView}
                                />
                            ))}
                        </div>
                        <div className="col-md-6">
                            <div className={styles.dameraHighlightImgContainer}>
                                <Carousel
                                    slideIndex={active}
                                    dragging={false}
                                    withoutControls
                                    swiping={false}
                                    speed={1500}
                                    animation="fade"
                                    scrollMode="remainder"
                                    cellAlign="center"
                                >
                                    {features.map((feature) => (
                                        <img
                                            key={feature.image.childImageSharp.fluid.src}
                                            className={styles.dameraHighlightImg}
                                            alt=""
                                            src={feature.image.childImageSharp.fluid.src}
                                        />
                                    ))}
                                </Carousel>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default Highlights;
